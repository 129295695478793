<template>
  <div class="supplier">
    <!-- <div class="supplier_qi">
      <el-radio v-model="radio" label="1">启用</el-radio>
      <el-radio v-model="radio" label="2">停用</el-radio>
    </div> -->
    <div class="supplier_box">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="供应链" name="1">

          <div class="supplier_div">
            <span>供应商</span>
            <div class="supplier_box_d">
              <h3 @click="dialogVisible = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3 @click="dialogVisibleS = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>

          <div class="supplier_div">
            <span>报价单</span>
            <div class="supplier_box_d">
              <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3 @click="dialogVisibleS1 = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>

          <div class="supplier_div">
            <span>付款单</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_fukuandan_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>

          <div class="supplier_div">
            <span>发票单</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_fapiaodan_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>采购单</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_caigou_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>出库单</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_chukudan_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>入库单</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_rukudan_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>开票</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_kaipiao_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>库存</span>
            <div class="supplier_box_d">
              <h3 @click="dialogVisible_kucun_daoru = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3 @click="dialogVisible_kucun_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

          <div class="supplier_div">
            <span>盘点</span>
            <div class="supplier_box_d">
              <!-- <h3 @click="dialogVisible1 = true"><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3 @click="dialogVisible_pandian_daochu = true"><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span>
              </h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div> 

        </el-collapse-item>
        <el-collapse-item v-show="false" title="菜品分类" name="2">
          <div class="supplier_div">
            <span>报价单</span>
            <div class="supplier_box_d">
              <h3><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>
        
        <el-collapse-item  title="订单收银" name="3">
          <div class="supplier_div">
            <span>订单收银</span>
            <div class="supplier_box_d">
              <!-- <h3><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3  @click="dialogVisible_dingdan_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item  title="菜品分类" name="4">
          <div class="supplier_div">
            <span>菜品信息</span>
            <div class="supplier_box_d">  
              <h3  @click="dialogVisible_caipinxi_daoru = true" ><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3  @click="dialogVisible_caipinxi_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item  title="人员管理" name="5">
          <div class="supplier_div">
            <span>人员信息</span>
            <div class="supplier_box_d">  
              <h3  @click="dialogVisible_renyuan_daoru = true" ><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3  @click="dialogVisible_renyuan_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item  title="打卡" name="6">
          <div class="supplier_div">
            <span>打卡</span>
            <div class="supplier_box_d">
              <!-- <h3><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3  @click="dialogVisible_daka_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>

        <el-collapse-item  title="会员卡" name="7">
          <div class="supplier_div">
            <span>会员卡</span>
            <div class="supplier_box_d">
              <h3  @click="dialogVisible_huiyuanka_daoru = true"  ><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3>
              <h3  @click="dialogVisible_huiyuanka_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>

          <div class="supplier_div">
            <span>会员卡充值信息</span>
            <div class="supplier_box_d">
              <!-- <h3><img src="../../../assets/img/tina-3.png" alt=""><span>导入表格</span></h3> -->
              <h3  @click="dialogVisible_huiyuanka_chongzhi_daochu = true" ><img src="../../../assets/img/tina-4.png" alt=""><span>导出数据</span></h3>
              <span>启用</span>
              <span>停用</span>
            </div>
          </div>
        </el-collapse-item>


      </el-collapse>
    </div>
<!-- 批量导入供应商 -->
    <el-dialog title="提示" :visible.sync="dialogVisible" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入供应商</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">
            <p>1.下载供应商录入单，填写供应商信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad()">下载模板</h4>
          </div>
          <div class="supplier_right">
            <p>1.下载供应商录入单，填写供应商信息</p>
            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false;sure()">确 定</el-button>
      </span>
    </el-dialog>

<!-- 导出供应商 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleS" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>导出供应商</h3>
        <p class="supplier_p">法律声明:供应商导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleS = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleS = false;daochugongyingshang()">确 定</el-button>
      </span>
    </el-dialog>


<!-- 批量导入报价单 -->
    <el-dialog title="提示" :visible.sync="dialogVisible1" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入报价单</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">

            <p>1.下载报价单，填写报价信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad1()">下载模板</h4>
          </div>
          <div class="supplier_right">

            <p>
              <el-select v-model="supply" placeholder="请选择供应商">
                <el-option v-for="item in supplyList" :key="item.value" :label="item.supply_name"
                  :value="item.supply_id">
                </el-option>
              </el-select>
            </p>

            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible1 = false;sure1()">确 定</el-button>
      </span>
    </el-dialog>

<!-- 导出报价单 -->
    <el-dialog title="提示" :visible.sync="dialogVisibleS1" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>导出报价单</h3>
        <p class="supplier_p">法律声明:报价单导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>
         <p>
              <el-select v-model="supply" placeholder="请选择供应商">
                <el-option v-for="item in supplyList" :key="item.value" :label="item.supply_name"
                  :value="item.supply_id">
                </el-option>
              </el-select>
            </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleS1 = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibleS1 = false;daochugongyingshang1()">确 定</el-button>
      </span>
    </el-dialog>


      <!-- 导出订单收银 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_dingdan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>导出订单收银</h3>
        <div class="time_xuanze">
          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择时间 </div></el-col>
            <el-col :span="12"><div class="grid-content bg-purple">
              <el-date-picker
              v-model="value1"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="placeholder_dingdanshouyin"
              >
            </el-date-picker>
          </div></el-col>
          </el-row>
          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="value" placeholder="请选择"  @change="select_dingdanshouyin">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

        </div>

        <p class="supplier_p">法律声明:订单收银导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_dingdan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_dingdan_daochu = false;daochudingdanshouyi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导入菜品信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_caipinxi_daoru" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入菜品信息</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">
            <p>1.下载菜品信息单，填写菜品信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad_caipinxinxi()">下载模板</h4>
          </div>
          <div class="supplier_right">
            <p>1.下载菜品信息录入单，填写菜品信息</p>
            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_caipinxi_daoru = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_caipinxi_daoru = false;sure_caipinxin()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出菜品信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_caipinxi_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>导出菜品</h3>
        <p class="supplier_p">法律声明:菜品导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>
         <!-- <p>
              <el-select v-model="supply" placeholder="请选择供应商">
                <el-option v-for="item in supplyList" :key="item.value" :label="item.supply_name"
                  :value="item.supply_id">
                </el-option>
              </el-select>
            </p> -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_caipinxi_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_caipinxi_daochu = false;daochucaipinxinxi()">确 定</el-button>
      </span>
    </el-dialog>

      <!-- 导入人员信息  -->
    <el-dialog title="提示" :visible.sync="dialogVisible_renyuan_daoru" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入人员信息</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">
            <p>1.下载人员信息单，填写人员信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad_renyuanxinxi()">下载模板</h4>
          </div>
          <div class="supplier_right">
            <p>1.下载人员信息录入单，填写人员信息</p>
            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_renyuan_daoru = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_renyuan_daoru = false;sure_renyuan()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible_renyaun_daoru_chengong"
      width="30%"
      :before-close="handleClose">
        <div class="chenggong_div">导入模板: {{moban_ming}} </div>
        <div class="chenggong_div">总计导入:{{daoru_zongshu}}条</div>
        <div class="chenggong_div">导入成功:{{daoru_cheng_gong}}条</div>
        <div class="chenggong_div" style="color:red" @click="todaoru_chaxun">导入失败:{{fail_size}}条</div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_renyaun_daoru_chengong = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_renyaun_daoru_chengong = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出人员信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_renyuan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>人员导出</h3>
        <p class="supplier_p">法律声明:人员导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_renyuan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_renyuan_daochu = false;daochurenyuanxinxi()">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 导出打卡信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_daka_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>打卡记录</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="daka_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>
        <p class="supplier_p">法律声明:打卡记录导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_daka_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_daka_daochu = false;daochudakaxinxi()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 导出付款单信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_fukuandan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>付款单信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="fukuandan_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择供应商 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="fukuandan_supply_id" placeholder="请选择供应商">
                <el-option v-for="item in supplyList" :key="item.value" :label="item.supply_name"
                  :value="item.supply_id">
                </el-option>
              </el-select>
          </div></el-col>
          </el-row>

        <p class="supplier_p">法律声明:付款单导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_fukuandan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_fukuandan_daochu = false;daochufukuandanxinxi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出发票单信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_fapiaodan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>发票单信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="fapiao_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择供应商 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="fapiao_supply_id" placeholder="请选择供应商">
                <el-option v-for="item in supplyList" :key="item.value" :label="item.supply_name"
                  :value="item.supply_id">
                </el-option>
              </el-select>
          </div></el-col>
          </el-row>

        <p class="supplier_p">法律声明:供应商导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_fapiaodan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_fapiaodan_daochu = false;daochufapiaodanxinxi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出发票单信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_caigou_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>采购单信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="caigou_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择时间 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-date-picker
              v-model="caigou_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              >
            </el-date-picker>
          </div>
          </el-col>
          </el-row>

        <p class="supplier_p">法律声明:采购单导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_caigou_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_caigou_daochu = false;daochucaigoudanxinxi()">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 导出出库单信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_chukudan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>出库单信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="chuku_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择时间 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-date-picker
              v-model="chuku_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              >
            </el-date-picker>
          </div>
          </el-col>
          </el-row>

        <p class="supplier_p">法律声明:出库单导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_chukudan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_chukudan_daochu = false;daochuchukudanxinxi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出入库单信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_rukudan_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>入库单信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="ruku_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择时间 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-date-picker
              v-model="ruku_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              >
            </el-date-picker>
          </div>
          </el-col>
          </el-row>

        <p class="supplier_p">法律声明:入库单导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_rukudan_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_rukudan_daochu = false;daochurukudanxinxi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出 开票 信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_kaipiao_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>开票信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="kaipiao_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择时间 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-date-picker
              v-model="kaipiao_time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              >
            </el-date-picker>
          </div>
          </el-col>
          </el-row>

        <p class="supplier_p">法律声明:开票信息导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_kaipiao_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_kaipiao_daochu = false;daochukaipiaoxinxi()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出会员卡信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_huiyuanka_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>会员卡信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="huiyuanka_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>
        <p class="supplier_p">法律声明:会员卡导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_huiyuanka_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_huiyuanka_daochu = false;daochuhuiyuankaxinxi()">确 定</el-button>
      </span>
    </el-dialog>

      <!-- 导入 会员卡 信息  -->
    <el-dialog title="提示" :visible.sync="dialogVisible_huiyuanka_daoru" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入会员卡信息</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">
            <p>1.下载会员卡信息单，填写会员卡信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad_huiyuanka_xinxi()">下载模板</h4>
          </div>
          <div class="supplier_right">
            <p>1.下载会员卡信息录入单，填写会员卡信息</p>
            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_huiyuanka_daoru = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_huiyuanka_daoru = false;sure_huiyuanka_daoru()">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 导出会员卡充值信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_huiyuanka_chongzhi_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>会员卡充值信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="huiyuanka_chongzhi_shop" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>
        <p class="supplier_p">法律声明:会员卡充值信息导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_huiyuanka_chongzhi_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_huiyuanka_chongzhi_daochu = false;daochuhuiyuanka_chongzhi_xinxi()">确 定</el-button>
      </span>
    </el-dialog>


    <!-- 导出 库存 信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_kucun_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>库存信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="kucun_shop_id" placeholder="请选择">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择仓库 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-select v-model="kucun_site_id" placeholder="请选择">
                  <el-option
                    v-for="item in cangku_list"
                    :key="item.site_id"
                    :label="item.site_name"
                    :value="item.site_id"
                    >
                  </el-option>
                </el-select>
          </div>
          </el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择菜系 </div></el-col>
            <el-col :span="12" >
              <div class="grid-content bg-purple">
              <el-select v-model="kucun_cat_id" placeholder="请选择">
                  <el-option
                    v-for="item in kucun_caixi"
                    :key="item.cat_id"
                    :label="item.cat_name"
                    :value="item.cat_id"
                    >
                  </el-option>
                </el-select>
          </div>
          </el-col>
          </el-row>

        <p class="supplier_p">法律声明:库存导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_kucun_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_kucun_daochu = false;daochukucunxinxi()">确 定</el-button>
      </span>
    </el-dialog>

      <!-- 导入 库存 信息  -->
    <el-dialog title="提示" :visible.sync="dialogVisible_kucun_daoru" width="60%" :before-close="handleClose">
      <div class="supplier_dialog">
        <h3>批量导入库存信息</h3>
        <div class="supplier_dialog_box">
          <div class="supplier_left">
            <p>1.下载库存信息单，填写库存信息</p>
            <div>
              <img src="../../../assets/img/xiazai1.png" alt="">
            </div>
            <h4 @click="DownLoad_kucunxinxi()">下载模板</h4>
          </div>
          <div class="supplier_right">
            <p>1.下载库存信息录入单，填写库存信息</p>
            <div>
              <img src="../../../assets/img/xiazai2.png" alt="">
              <span>仅支持xls / xlsx格式文件</span>
              <span>单次导入条数不超过10000条</span>
            </div>
            <el-upload class="upload-demo" ref="upload" action='' :multiple="false" :auto-upload="false">
              <h4 style="margin-left:100px">上传文件</h4>
              <!-- <el-button style="background: #9a86db;border:none;margin-left:15px;margin-right:30px; padding:6px 4px" slot="trigger" size="small" type="primary">上传文件</el-button> -->
            </el-upload>
            <!-- <h4>上传文件</h4> -->
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_kucun_daoru = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_kucun_daoru = false;sure_kucun()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 导出 盘点信息 信息 -->
    <el-dialog title="提示" :visible.sync="dialogVisible_pandian_daochu" width="60%" :before-close="handleCloseS">
      <div class="supplier_dialog">
        <h3>盘点信息</h3>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择店铺 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="pandian_shop_id" placeholder="请选择" @change="pandian_Fun">
                  <el-option
                    v-for="item in options_dingdanshouyin"
                    :key="item.shop_id"
                    :label="item.shop_name"
                    :value="item.shop_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

          <el-row>
            <el-col :span="6" :offset="2"><div class="grid-content bg-purple"> 请选择盘点 </div></el-col>
            <el-col :span="12" ><div class="grid-content bg-purple">
              <el-select v-model="pandian_id" placeholder="请选择">
                  <el-option
                    v-for="item in pandian_list"
                    :key="item.check_id"
                    :label="item.check_id"
                    :value="item.check_id"
                    >
                  </el-option>
                </el-select>
          </div></el-col>
          </el-row>

        <p class="supplier_p">法律声明:盘点导出服务由餐亿本研发并经授权企业管理员选择开通，使用，留存，目前相关数据存储于云端服务器已通过相关机构认证，请放心使用。</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible_pandian_daochu = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible_pandian_daochu = false;daochupandianxinxi()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import axios from 'axios'

  import { query_shop_info_list, query_store_house_list,query_check_no_list,export_supply_quotation_template } from "../../../api/store"

  import { query_category_info_list } from '@api/foodqiye'

let baseURL = ''
if(process.env.NODE_ENV === 'development'){ 
    //开发环境 do something
    console.log('开发环境 do something');
    baseURL = 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0'
}else{
    //生产环境 do something
    console.log('//生产环境 do something');
    // baseURL = 'https://cyb.meilibaobiao.com:8443/cbreat/V1.0.0'
    baseURL = 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0'


}



  export default {
    data() {
      return {
        activeNames: ['1'],  // arrary  不开启手风琴模式   string 开启手风琴模式
        dialogVisible: false,
        dialogVisibleS: false,
        dialogVisible1: false,
        dialogVisibleS1: false,

        // 仓库 列表
        cangku_list:[],
        // 库存菜系
        kucun_caixi:[],

        //  订单收银
        dialogVisible_dingdan_daochu:false,
        value1:'', // 导出时间选择 开始-结束
        options_dingdanshouyin: [],
        value: '', // 选择的门店  订单收银

        // 菜品导入 导出---导出
        dialogVisible_caipinxi_daochu:false,
        // 菜品导入 导出---导入
        dialogVisible_caipinxi_daoru:false, 

        // 人员 导出 
        dialogVisible_renyuan_daochu:false,
        // 人员 导入
        dialogVisible_renyuan_daoru:false,
        
        dialogVisible_renyaun_daoru_chengong:false,

        // 打卡记录所属的店铺
        daka_shop:"",
        // 打卡导出 
        dialogVisible_daka_daochu:false,

        // 付款单信息的店铺
        fukuandan_shop:"",
        // 付款单供应商
        fukuandan_supply_id:'',
        // 付款单信息导出 
        dialogVisible_fukuandan_daochu:false,

        // ---------发票单
        fapiao_shop:'', // 发票门店
        fapiao_supply_id:'', // 发票供应商
        dialogVisible_fapiaodan_daochu:false,

        // ---------采购单
        caigou_shop:'',
        caigou_time:'', // 时间
        dialogVisible_caigou_daochu:false,

        // ------- 出库单 导出
        chuku_shop:'',
        chuku_time:'',
        dialogVisible_chukudan_daochu:false,
        // --------入库单 导出
        ruku_shop:'',
        ruku_time:'',
        dialogVisible_rukudan_daochu:false,

        // 导出会员卡
        huiyuanka_shop:"",
        dialogVisible_huiyuanka_daochu:false,
        dialogVisible_huiyuanka_daoru:false,

        // 会员卡充值信息 
        huiyuanka_chongzhi_shop:'',
        dialogVisible_huiyuanka_chongzhi_daochu:false,
        // -------开票
        kaipiao_time:'',
        kaipiao_shop:'',
        dialogVisible_kaipiao_daochu:false,

        // --------库存导出
        kucun_site_id:'',
        kucun_shop_id:'',
        kucun_cat_id:'',
        dialogVisible_kucun_daochu:false,

        dialogVisible_kucun_daoru:false, // 导入

        // -- --- --- --- 盘点
        pandian_shop_id:'',
        pandian_list:[],
        pandian_id:'',
        dialogVisible_pandian_daochu:false,





        //
        moban_ming:'', // 名称
        daoru_zongshu:'', // 总数
        daoru_cheng_gong:'', // 成功
        fail_size:'',  // 失败

        // 
        radio: '1',
        supply: '', //报价单 的 供应商 id
        supplyList: []
      }
    },
    created() {
      let data = {
        data: {
          ent_id: this.$store.state.ent_id
        }
      }
      // axios.post('https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/query_supply_list', data).then(res => {
      axios.post(`${baseURL}/query_supply_list`, data).then(res => {
        console.log('数据中心',JSON.parse(res.data.body.data))
        this.supplyList = JSON.parse(res.data.body.data)
      })
    },
    mounted(){
      // 获取店铺列表
      this.query_shop_info_list()
      // 仓库列表
      this.query_store_house_list()
      // 菜系列表
      this.query_category_info_list()

      // 
      // this.pandauin()
    },
    methods: {


      pandauin(){
        let baseURL = ''
        if(process.env.NODE_ENV === 'development'){ 
            //开发环境 do something
            console.log('开发环境 do something');
            baseURL = 'https://cscyb.meilibaobiao.com:8443/cbreat/V1.0.0'
        }else{
            //生产环境 do something
            console.log('//生产环境 do something');
            baseURL = 'https://cyb.meilibaobiao.com:8443/cbreat/V1.0.0'
        }
      },



      // 报价单
      sure1() {
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)

        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('supply_id', this.supply)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              // url: 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_quotation_ai',
              url: `${baseURL}/import_quotation_ai`,
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {

              let code = res.data.code

              if (code === '10133') {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
              }else if (code === '200') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-报价单导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },
      // 供应商 导出
      daochugongyingshang() {
        window.open(
          // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_supply?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}`
          `${baseURL}/export_supply?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}`
        )
      },
       daochugongyingshang1() {
        window.open(
          // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_supply_quotation?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}&supply_id=${this.supply}`
          `${baseURL}/export_supply_quotation?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}&supply_id=${this.supply}`
        )
      },
      DownLoad1() {
        // // // axios.get('')
        // export_supply_quotation_template({data:{
        //   ent_id:this.$store.state.ent_id,
        //   supply_id:this.supply
        // }}).then((res)=>{
        //   console.log(res);
        // })
        if (this.supply != '') {
          window.open(
            `${baseURL}/export_supply_quotation_template?ent_id=${this.$store.state.ent_id}&supply_id=${this.supply}`
          )
        }else{
          this.$message({
            message: '请选择供应商',
            type: 'success'
          })
        }

      },
      sure() {
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)

        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              url: `${baseURL}/import_supply_ai`, //'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_supply_ai',
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {

              let code = res.data.code

              if (code === '10133') {
                this.$message({
                  message: '导入成功',
                  type: 'success'
                })
              }else if (code === '200') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-供应商导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },
      // 供应商 导入 模板
      DownLoad() {

        // const iframe = document.createElement('iframe')
        // iframe.style.display = 'none'
        // iframe.style.height = 0
        // iframe.src = 'https://cyb.meilibaobiao.com/template/餐亿本-供应商导入模板.xlsx'
        // document.body.appendChild(iframe)
        // setTimeout(res => {
        //   iframe.remove()
        // }, 5 * 60 * 1000)

        console.log('供应商信息导入的模板下载');
        let a = document.createElement("a");
        console.log('aaaaa',a);
        a.download = "餐亿本-供应商导入模板.xls";
        a.href = 'https://cyb.meilibaobiao.com/template/餐亿本-供应商导入模板.xlsx';
        document.body.append(a); // 修复firefox中无法触发click
        a.click();
        //  $(a).remove();
          document.body.removeChild(a);




      },
      handleChange(val) {
        console.log(val);
      },

      //关闭
      handleClose(done) {
        done();
      },
      // 关闭
      handleCloseS(done) {
        done();
      },
      // ==============店铺的列表===================
      query_shop_info_list(){
        query_shop_info_list({
          data: {
            ent_id: this.$store.state.ent_id
            // end_id:sessionStorage.getItem('pc_ent_id')
          }
        }).then(res => {
          console.log(res);
          if (res.code=='200') {
            this.options_dingdanshouyin = res.body.data
          }
        })

      },
      // 仓库 列表
      query_store_house_list(){
        query_store_house_list({
          data:{
            ent_id: this.$store.state.ent_id,
            active: '1'
          }
        }).then(res=>{
          console.log(res);
          this.cangku_list = res.body.data

        })
      },
      // 菜系列表
      query_category_info_list(){
        query_category_info_list({
          data: {
            ent_id:this.$store.state.ent_id,
            active: '1'
          }
        }).then((res) => {
          console.log(res);
          if (res.code == 10193) {
            this.kucun_caixi = res.body.data
          }
        })

      },
      // 盘点list
      query_check_no_list(shop_id){
        query_check_no_list({
          data:{
            ent_id:this.$store.state.ent_id,
            shop_id:shop_id
          }
        }).then((res)=>{
          console.log(res)
          if (res.code == 200) {
            let pandian_list = res.body.data
            let pan_list = []
            for (let a in pandian_list) {
              pan_list.push({
                check_id:pandian_list[a]
              })
            }
            this.pandian_list = pan_list
          }
        })
      },
      pandian_Fun(val){
        this.query_check_no_list(val)
      },
      //==============================================
      // 日期格式转换 转换成2019-12-12
      date (date) {
        // let nowdate = new Date(date).toLocaleDateString().replace(/\//g, '-')
        // return nowdate
        //shijianchuo是整数，否则要parseInt转换
        let time = new Date(date);
        var y = time.getFullYear();
        var m = time.getMonth()+1;
        var d = time.getDate();
        // var h = time.getHours();
        // var mm = time.getMinutes();
        // var s = time.getSeconds();
        return y+'-'+this.add0(m)+'-'+this.add0(d) //+' '+add0(h)+':'+add0(mm)+':'+add0(s);
      },
      add0(m){return m<10?'0'+m:m },


      // 选择时间  没用 上
      placeholder_dingdanshouyin(val){
        console.log(val);
        console.log('value1',this.value1);
        console.log('value1', this.date(this.value1[0]) );
        console.log('value1', this.date(this.value1[1]) );
      },
      // 选择 门店id 没用 上
      select_dingdanshouyin(val){
        console.log(val);
        console.log('this.value',this.value);
      },


      // 订单收银导出链接
      daochudingdanshouyi(){
        if (this.value!=''&&this.value1.length!=0) {
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_order_cash?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}&supply_id=${this.supply}`
            `${baseURL}/export_order_cash?ent_id=${this.$store.state.ent_id}&shop_id=${this.value}&date_start=${this.date(this.value1[0])}&date_end=${this.date(this.value1[1])}`
          )  
        }                                                                                                                                    
      },        


      // 菜品信息 导入模板下载
      DownLoad_caipinxinxi(){
        console.log('菜品信息导入的模板下载');
        let a = document.createElement("a");
        console.log('aaaaa',a);
        a.download = "餐亿本-菜品导入模板.xls";
        a.href = 'https://cyb.meilibaobiao.com/template/餐亿本-菜品导入模板.xlsx';
        document.body.append(a); // 修复firefox中无法触发click
        a.click();
        //  $(a).remove();
          document.body.removeChild(a);

        // const iframe = document.createElement('iframe')
        // iframe.style.display = 'none'
        // iframe.style.height = 0
        // iframe.src = 'https://cyb.meilibaobiao.com/template/餐亿本-菜品导入模板.xlsx'
        // document.body.appendChild(iframe)
        // setTimeout(res => {
        //   iframe.remove()
        // }, 5 * 60 * 1000)
      },
      // 菜品模板信息 上传
      sure_caipinxin(){
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])  // 格式不对 失败
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              url: `${baseURL}/import_prod_ai`, //'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_prod_ai',
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {
              console.log(res);
              let code = res.data.code

              if (code === '10133') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-菜品导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
                // this.$message({
                //   message: `总计导入${res.data.body.fail_size}条 成功导入${res.data.body.success_size}条`,
                //   type: 'success'
                // })
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },
      // 菜品信息 的 导出 
      daochucaipinxinxi(){
        window.open(
          // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_order_cash?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}&supply_id=${this.supply}`  &ids=${this.value}
          `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_prod?ent_id=${this.$store.state.ent_id}&excel_type=${'2'}`
        )  
      },

      // 人员信息 导入模板下载
      DownLoad_renyuanxinxi(){
        let a = document.createElement("a");
        console.log('aaaaa',a);
        a.download = "餐亿本-员工导入模板.xlsx";
        a.href = 'https://cyb.meilibaobiao.com/template/餐亿本-员工导入模板.xlsx';
        document.body.append(a); // 修复firefox中无法触发click
        a.click();
        //  document.body.a.remove();
        document.body.removeChild(a);
      },
      // 人员信息模板 上传
      sure_renyuan(){
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])  // 格式不对 失败
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              url:  `${baseURL}/import_staff_ai`,// 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_staff_ai',
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {
              let code = res.data.code

              if (code === '10143') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-员工导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
                this.$message({
                  message: `总计导入${res.data.body.fail_size}条 成功导入${res.data.body.success_size}条`,
                  type: 'success'
                })
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },
      // 人员信息 导出
      daochurenyuanxinxi(){
        window.open(
          // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_staff?ent_id=${this.$store.state.ent_id}&excel_type=${'1'}`
          `${baseURL}/export_staff?ent_id=${this.$store.state.ent_id}&excel_type=${'1'}`
        ) 
      },


      // 导出打卡信息 
      daochudakaxinxi(){
        window.open(
          `${baseURL}/export_clock?ent_id=${this.$store.state.ent_id}&user_id=${sessionStorage.getItem('user_id')}&shop_id=${this.daka_shop}`
        ) 
      },

      // 导出付款单信息
      daochufukuandanxinxi(){
        window.open(
          `${baseURL}/export_payment?ent_id=${this.$store.state.ent_id}&shop_id=${this.fukuandan_shop}&supply_id=${this.fukuandan_supply_id}`
        ) 
      },

      // 导出 发票单 信息
      daochufapiaodanxinxi(){
        window.open(
          // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_pur_inv?ent_id=${this.$store.state.ent_id}&shop_id=${this.fapiao_shop}&supply_id=${this.fapiao_supply_id}`
          `${baseURL}/export_pur_inv?ent_id=${this.$store.state.ent_id}&shop_id=${this.fapiao_shop}&supply_id=${this.fapiao_supply_id}`
        ) 
      },

      // 导出采购单 
      daochucaigoudanxinxi(){
        if (this.caigou_shop!=''&&this.caigou_time.length!=0) {
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_pur?ent_id=${this.$store.state.ent_id}&shop_id=${this.caigou_shop}&date_start=${this.date(this.caigou_time[0])}&date_end=${this.date(this.caigou_time[1])}`
            `${baseURL}/export_pur?ent_id=${this.$store.state.ent_id}&shop_id=${this.caigou_shop}&date_start=${this.date(this.caigou_time[0])}&date_end=${this.date(this.caigou_time[1])}`

          )  
        } 
      },
      // 导出出库单
      daochuchukudanxinxi(){
        if (this.chuku_shop!=''&&this.chuku_time.length!=0) {
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_out_store?ent_id=${this.$store.state.ent_id}&shop_id=${this.caigou_shop}&date_start=${this.date(this.chuku_time[0])}&date_end=${this.date(this.chuku_time[1])}`
            `${baseURL}/export_out_store?ent_id=${this.$store.state.ent_id}&shop_id=${this.caigou_shop}&date_start=${this.date(this.chuku_time[0])}&date_end=${this.date(this.chuku_time[1])}`
          )  
        } else{
            this.$message({
              message: '请选择门店和时间',
              type: 'success'
            })
        }
      },
      // 导出入库单
      daochurukudanxinxi(){
        if (this.chuku_shop!=''&&this.chuku_time.length!=0) {
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_in_store?ent_id=${this.$store.state.ent_id}&shop_id=${this.ruku_shop}&date_start=${this.date(this.ruku_time[0])}&date_end=${this.date(this.ruku_time[1])}`
            `${baseURL}/export_in_store?ent_id=${this.$store.state.ent_id}&shop_id=${this.ruku_shop}&date_start=${this.date(this.ruku_time[0])}&date_end=${this.date(this.ruku_time[1])}`
          )  
        } 
      },

      // 导出 会员卡
      daochuhuiyuankaxinxi(){
          window.open(
            //`https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_vip_card?ent_id=${this.$store.state.ent_id}&shop_id=${this.huiyuanka_shop}`
            `${baseURL}/export_vip_card?ent_id=${this.$store.state.ent_id}&shop_id=${this.huiyuanka_shop}`
          )  
      },
      // 下载会员卡 信息导入 模板 
      DownLoad_huiyuanka_xinxi(){
        let a = document.createElement("a");
        console.log('aaaaa',a);
        a.download = "餐亿本-会员卡导入模板.xlsx";
        a.href = 'https://cyb.meilibaobiao.com/template/餐亿本-会员卡导入模板.xlsx';
        document.body.append(a); // 修复firefox中无法触发click
        a.click();
        //  document.body.a.remove();
        document.body.removeChild(a);
      },
      // 会员卡 模板 上传
      sure_huiyuanka_daoru(){
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])  // 格式不对 失败
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              url: `${baseURL}/import_vip_card_ai`,// 'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_vip_card_ai',
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {
              console.log(res);
              let code = res.data.code

              if (code === '10133') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-会员卡导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
                // this.$message({
                //   message: `总计导入${res.data.body.fail_size}条 成功导入${res.data.body.success_size}条`,
                //   type: 'success'
                // })
              } else if (code === '200') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-会员卡导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
                // this.$message({
                //   message: `总计导入${res.data.body.fail_size}条 成功导入${res.data.body.success_size}条`,
                //   type: 'success'
                // })
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },

      // 会员卡充值信息 导出
      daochuhuiyuanka_chongzhi_xinxi(){
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_member_topup?ent_id=${this.$store.state.ent_id}&shop_id=${this.huiyuanka_chongzhi_shop}`
            `${baseURL}/export_member_topup?ent_id=${this.$store.state.ent_id}&shop_id=${this.huiyuanka_chongzhi_shop}`
          )  
      },

      // 导出 开票
      daochukaipiaoxinxi(){
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_open_inv?ent_id=${this.$store.state.ent_id}&shop_id=${this.kaipiao_shop}&date_start=${this.date(this.kaipiao_time[0])}&date_end=${this.date(this.kaipiao_time[1])}`
            `${baseURL}/export_open_inv?ent_id=${this.$store.state.ent_id}&shop_id=${this.kaipiao_shop}&date_start=${this.date(this.kaipiao_time[0])}&date_end=${this.date(this.kaipiao_time[1])}`
       ) 
      },

      // 导出库存信息
      daochukucunxinxi(){
          window.open(
            // `https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_stock?ent_id=${this.$store.state.ent_id}&shop_id=${this.kucun_shop_id}&site_id=${this.kucun_site_id}&cat_id=${this.kucun_cat_id}`
            `${baseURL}/export_stock?ent_id=${this.$store.state.ent_id}&shop_id=${this.kucun_shop_id}&site_id=${this.kucun_site_id}&cat_id=${this.kucun_cat_id}`
          ) 
      },
      // 库存导入模板下载
      DownLoad_kucunxinxi(){
        let a = document.createElement("a");
        console.log('aaaaa',a);
        a.download = "餐亿本-库存导入模板.xlsx";
        a.href = 'https://cyb.meilibaobiao.com/template/餐亿本-库存导入模板.xlsx';
        document.body.append(a); // 修复firefox中无法触发click
        a.click();
        //  document.body.a.remove();
        document.body.removeChild(a);
      },
      // 库存模板信息 上传
      sure_kucun(){
        console.log('上传文件+上传文件', this.$refs.upload.uploadFiles)
        const date = this.$refs.upload.uploadFiles
        const list = []
        const shibai = []
        const fasong = []
        for (let i = 0; i < date.length; i++) {
          list.push(date[i].raw)
        }
        for (let i = 0; i < list.length; i++) {
          if (list[i].name.split('.')[1] != 'xlsx' && list[i].name.split('.')[1] != 'xls') {
            shibai.push(list[i])  // 格式不对 失败
          } else {
            const fileObj = list[i]
            const form = new FormData() // FormData 对象
            form.append('files', fileObj) // 文件对象  'upload'是后台接收的参数名
            form.append('ent_id', this.$store.state.ent_id)
            form.append('user_id', sessionStorage.getItem('user_id'))
            axios({
              method: 'post',
              url: `${baseURL}/import_stock_ai`, //'https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/import_stock_ai',
              data: form,
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }).then(res => {
              console.log(res);
              let code = res.data.code

              if (code === '10133') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-库存导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
                // this.$message({
                //   message: `总计导入${res.data.body.fail_size}条 成功导入${res.data.body.success_size}条`,
                //   type: 'success'
                // })
              } else if (code === '200') {
                this.dialogVisible_renyaun_daoru_chengong = true,
                this.moban_ming = '餐亿本-库存导入模板',
                this.daoru_cheng_gong = res.data.body.success_size,
                this.fail_size = res.data.body.fail_size,
                this.daoru_zongshu = res.data.body.fail_size*1+res.data.body.success_size*1
              } else if (code === '10144') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              } else if (code === '11800') {
                this.$message({
                  message: '导入文件的sheet页名不对',
                  type: 'error'
                })
              } else if (code === '11900') {
                this.$message({
                  message: '导入文件的模板格式不对',
                  type: 'error'
                })
              } else if (code === '10134') {
                this.$message({
                  message: '导入失败，请重新导入',
                  type: 'error'
                })
              }
            })

          }
        }
      },

      // 盘点信息 daochupandianxinxi
      daochupandianxinxi(){
          window.open(
            //`https://ctds.meilibaobiao.com:8443/cbreat/V1.0.0/export_check?ent_id=${this.$store.state.ent_id}&shop_id=${this.pandian_shop_id}&check_no=${this.pandian_id}`
            `${baseURL}/export_check?ent_id=${this.$store.state.ent_id}&shop_id=${this.pandian_shop_id}&check_no=${this.pandian_id}`
          ) 
      },

      // 跳转页面 导入失败记录查询
      todaoru_chaxun(){
        this.$router.push('/Inquire')
      },
    }
  }
</script>

<style scoed lang="scss">

.chenggong_div{
  font-size: 16px;
  margin: 6px 0 0 3px;
}

  .el-main {
    background: #f6f6fa;
    height: 800px;
  }

  .supplier {
    background: #fff;
    width: 100%;
    height: 800px;
  }

  .supplier_qi {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .supplier_box {
    width: 100%;
    margin: 0 auto;

    .supplier_div {
      padding: 10px 60px;
      display: flex;
      justify-content: space-between;

      .supplier_box_d {
        display: flex;

        span {
          margin-right: 20px;
          color: #333;
        }

        h3 {
          display: flex;
          margin-right: 30px;
        }

        p {
          display: block;
        }

        img {
          margin-right: 10px;
        }
      }
    }
  }

  .supplier_dialog {
    h3 {
      width: 250px;
      height: 62px;
      color: #fff;
      background: #FF0000;
      text-align: center;
      border-radius: 6px;
      line-height: 62px;
      font-size: 20px;
      margin: 0 auto 60px auto;
    }

    .supplier_dialog_box {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto 40px auto;

      &>div:first-of-type {
        margin-right: 10%;
      }

      &>div {
        width: 40%;

        &>div {
          height: 160px;

          span {
            display: block;
            text-align: center;
          }

          img {
            display: block;
            width: 100px;
            margin: 0 auto 30px auto;
          }
        }

        p {
          text-align: center;
          font-size: 15px;
          margin-bottom: 58px;
        }

        h4 {
          width: 180px;
          height: 62px;
          font-size: 20px;
          color: #fff;
          background: #FF0000;
          text-align: center;
          border-radius: 10px;
          line-height: 62px;
          margin: 10px auto 0 auto;
        }
      }
    }

    .el-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .el-col {
      border-radius: 4px;
    }
    .bg-purple {
      // background: #d3dce6;
      font-size: 16px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
    .grid-content {
      border-radius: 4px;
      min-height: 46px;

    }
    .row-bg {
      padding: 10px 0;
      background-color: #f9fafc;
    }


  }

  .supplier_p {
    margin: 100px 0;
    line-height: 20px;
    font-size: 16px;
  }
</style>
<style lang="scss">
  .supplier .el-collapse-item__header {
    background: linear-gradient(180deg, #FC4353, #FF5E41);
    color: #fff;
    padding: 0 40px;
    font-size: 16px;
    margin-top: 40px;
  }
</style>